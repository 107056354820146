export const SearchData = {
  en: [
    "Samsung",
    "Hisense",
    "Toshiba",
    "LG",
    "Washing Machines",
    "Samsung Tv",
    "Hisense Tv",
    "Cooker Gases",
    "Kitchen Accessories",
    "Sofas",
    "Coffee Tables",
    "Corner Sofas",
    "Toilets",
    "Sofa Sets",
    "iPhone 16",
    "AirPods",
    "MacBook",
    "Kitchen Furniture",
    "Dyson",
    "Dyson Airwrap",
    "Sanitary ware",
    "PlayStation",
    "Refrigerators",
    "Smartphones",
    "Apple",
    "Garden",
    "William’s Art Furniture",
    "Vikass",
    "Dishwashers",
    "Household Goods",
    "Tools",
    "Coffee Machines",
    "Samsung smartphones",
    "Doors",
    "Smart Refrigerators",
    "Air Conditioners",
    "Smart TV",
    "Bedding Sets",
    "Notebooks",
    "Dishware",
    "Tablets",
    "Bread Makers",
    "For Kitchen",
    "Multicookers",
    "Grill Makers",
  ],
  ru: [
    "Samsung",
    "Hisense",
    "Toshiba",
    "LG",
    "Стиральные машины",
    "Samsung Tv",
    "Hisense Tv",
    "Газовые плиты",
    "Кухонные принадлежности",
    "Диваны",
    "Журнальные столы",
    "Угловые диваны",
    "Унитазы",
    "Комплекты мягкой мебели",
    "iPhone 16",
    "AirPods",
    "MacBook",
    "Кухонная мебель",
    "Dyson",
    "Dyson Airwrap",
    "Санкерамика",
    "PlayStation",
    "Холодильники",
    "Смартфоны",
    "Apple",
    "Сад",
    "William’s Art Furniture",
    "Vikass",
    "Посудомоечные машины",
    "Хозяйственные товары",
    "Инструменты",
    "Кофемашины",
    "Samsung смартфоны",
    "Двери",
    "Smart холодильники",
    "Кондиционеры",
    "Smart TV",
    "Постельное белье",
    "Ноутбуки",
    "Посуда",
    "Планшеты",
    "Хлебопечи",
    "Для кухни",
    "Мультиварки",
    "Грильницы",
  ],
  hy: [
    "Samsung",
    "Hisense",
    "Toshiba",
    "LG",
    "Լվացքի մեքենաներ",
    "Samsung Tv",
    "Hisense Tv",
    "Գազօջախներ",
    "Խոհանոցային պարագաներ",
    "Բազմոցներ",
    "Սուրճի սեղաններ",
    "Անկյունային բազմոցներ",
    "Զուգարանակոնքեր",
    "Փափուկ կահույքի հավաքածուներ",
    "iPhone 16",
    "AirPods",
    "MacBook",
    "Խոհանոցային կահույք",
    "Dyson",
    "Dyson Airwrap",
    "Սանկերամիկա",
    "PlayStation",
    "Սառնարաններ",
    "Սմարթֆոններ",
    "Apple",
    "Այգի",
    "William’s Art Furniture",
    "Vikass",
    "Սպասք լվացող մեքենաներ",
    "Տնտեսական պարագաներ",
    "Գործիքներ",
    "Սուրճի մեքենաներ",
    "Samsung smartphones",
    "Դռներ",
    "Smart սառնարաններ",
    "Օդորակիչներ",
    "Smart TV",
    "Անկողնային հավաքածու",
    "Նոութբուքեր",
    "Սպասք",
    "Tablets",
    "Հացթուխ սարքեր",
    "Խոհանոցի համար",
    "Մուլտիխուկեր",
    "Գրիլ պատրաստող սարքեր",
  ],
};
