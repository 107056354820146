import React, { useState } from "react";
import styles from "./GiftItem.module.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
// import IsAvailable from "assets/SVG/IsAvailable";

const GiftItem = ({ handlerChooseGift, prod, breadcrumbs = [], activeGiftId, setActiveGiftId }) => {
  const { currentLanguage } = useSelector(({ main }) => main);
  
  if (breadcrumbs?.length > 0 && breadcrumbs?.[0]?.slug === 'furniture') { 
    const handleClick = (id) => { 
      setActiveGiftId(id);
      handlerChooseGift(id);
    };
    const itemStyles = activeGiftId === prod?.product_id ? styles.activeGift : "";
    return (
      <div
        className={`${styles.GiftItem} ${itemStyles}`}
        onClick={() => handleClick(prod?.product_id)}
      >
        {/* <IsAvailable style={{ minWidth: "12.29px", minHeight: "12.29px" }} /> */}
        <img
          src={process.env.REACT_APP_STORAGE_URL + prod?.image}
          alt=""
          className={styles.GiftImage}
        />

        <p className={styles.GiftText}>{prod?.product_name}</p>
      </div>
    );

  } else {
    return (
      <Link
        to={`${currentLanguage !== "hy" ? "/" + currentLanguage : ""}/Product/${prod?.product_id
          }`}
        className={styles.GiftItem}>
        {/* <IsAvailable style={{ minWidth: "12.29px", minHeight: "12.29px" }} /> */}
        <img
          src={process.env.REACT_APP_STORAGE_URL + prod?.image}
          alt=""
          className={styles.GiftImage}
        />

        <p className={styles.GiftText}>{prod?.product_name}</p>
      </Link>
    );
  }

};

export { GiftItem };
