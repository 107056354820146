import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "./request";
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  filters: [],
  filter: null,
  banners: [],
  history: [],
  categoriesWs: [],
  checkout: {
    activeStep: 0,
    cart: [],
    subtotal: 0,
    total: 0,
    discount: 0,
    shipping: 0,
    billing: null,
  },
};

const slice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetCart(state) {
      state.checkout.activeStep = 0;
      state.checkout.cart = [];
      state.checkout.total = 0;
      state.checkout.subtotal = 0;
      state.checkout.discount = 0;
      state.checkout.shipping = 0;
      state.checkout.billing = null;
    },

    getFiltersSuccess(state, action) {
      state.isLoading = false;
      state.filters = action.payload;
    },
    getFiltersBannersSuccess(state, action) {
      state.isLoading = false;
      state.banners = action.payload;
    },
    getHistorySearch(state, action) {
      state.isLoading = false;
      state.history = action.payload;
    },
    setCategories(state, action) {
      state.isLoading = false;
      state.categoriesWs = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getFiltersSuccess,setCategories, resetCart, getFilterPagination, getFiltersBannersSuccess, getHistorySearch } =
  slice.actions;

// ----------------------------------------------------------------------
export function searchWsPageBanners() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosInstance.post(
        `sales/discount`
      );
      dispatch(slice.actions.getFiltersBannersSuccess(response.data));
    } catch (error) {
      console.error("Error fetching filter:", error);
      dispatch(
        slice.actions.hasError(error.response?.data || "Error fetching filter")
      );
    }
  };
}
export function ClearHistoryId(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const formData = new FormData();
      const session_id = document.querySelector('meta[name="s_d"]')?.content;
      formData.append("session_id", session_id);
      formData.append("id", id);
      const response = await axios.post(
        `https://v1.vlv.am/api/search/history/${id}`, formData
      );

      dispatch(slice.actions.getHistorySearch(response.data.data));
    } catch (error) {
      console.error("Error fetching HistorySearch:", error);
      dispatch(
        slice.actions.hasError(error.response?.data || "Error fetching HistorySearch")
      );
    }
  };
}
export function ClearHistory() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const formData = new FormData();
      const session_id = document.querySelector('meta[name="s_d"]')?.content;
      formData.append("session_id", session_id);
      const response = await axios.post(
        `https://v1.vlv.am/api/search/history/clear`, formData
      );

      dispatch(slice.actions.getHistorySearch(response.data));
    } catch (error) {
      console.error("Error fetching HistorySearch:", error);
      dispatch(
        slice.actions.hasError(error.response?.data || "Error fetching HistorySearch")
      );
    }
  };
}
export function HistorySearch() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const formData = new FormData();
      const session_id = document.querySelector('meta[name="s_d"]')?.content;
      formData.append("session_id", session_id);

      const response = await axios.post(
        `https://v1.vlv.am/api/search/history`, formData
      );

      dispatch(slice.actions.getHistorySearch(response.data.data));
    } catch (error) {
      console.error("Error fetching HistorySearch:", error);
      dispatch(
        slice.actions.hasError(error.response?.data || "Error fetching HistorySearch")
      );
    }
  };
}
export function createHistory(searchText) {
  return async (dispatch) => {
    const session_id = document.querySelector('meta[name="s_d"]')?.content;
    const formData = new FormData();
    formData.append("searchText", searchText);
    formData.append("session_id", session_id);

    try {
      await axios.post('https://v1.vlv.am/api/save/search/history', formData);

      dispatch({
        type: 'SAVE_SEARCH_HISTORY_SUCCESS',
        payload: { searchText, session_id }
      });
    } catch (err) {
      console.error("Error saving search history:", err);

      dispatch({
        type: 'SAVE_SEARCH_HISTORY_FAILURE',
        error: err.message
      });
    }
  };
}
 
export function categories(ids) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const formData = new FormData(); 
    if (Array.isArray(ids)) {
      ids.forEach((id) => formData.append("ids[]", id));  
    } else {
      formData.append("ids", ids);
    }

    try {
      const response = await axios.post('https://v1.vlv.am/api/categories', formData); 
      dispatch(slice.actions.setCategories(response.data));
     
    } catch (err) {
      console.error("Error categories:", err); 
      dispatch(slice.actions.hasError(err.message));
      
    }  
  };
}

export function searchWsPage(WSsearchInfo, navigate, currentLanguage, searchText) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const session_id = document.querySelector('meta[name="s_d"]')?.content;
      const categorySlug = WSsearchInfo?.category?.slug ?? 0;
      const brandId = WSsearchInfo?.brand?.id ?? 0;
      const search = WSsearchInfo?.search ?? 0;
      const brandSlug = WSsearchInfo?.brand?.slug ?? 0;
      const product_id = WSsearchInfo?.product_id ?? 0;
      const urlWs = WSsearchInfo?.url ?? 0;
      const formData = new FormData();
      formData.append("searchText", searchText);
      formData.append("session_id", session_id);
      axios.post(`https://v1.vlv.am/api/save/search/history`, formData)
        .catch(err => console.error("Error saving search history:", err));


      if (urlWs) {
        window.location.href = urlWs;
        return;
      }
      if (product_id) {
        navigate(`Product/${product_id}`);
        return; // Завершаем выполнение, если навигация уже произошла
      }

      if (categorySlug) {
        formData.append("slug", categorySlug);
      }
      if (brandId) {
        formData.append("b", brandId);
      }
      if (!categorySlug && !brandId && search) {
        formData.append("search", search);
        formData.append("searchInfo", 1);
      }
      if(brandId && search){
        formData.append("search", search);
        formData.append("searchInfo", 1);
      }
      if (search) {
        formData.append("fs", search);
      }


      if (categorySlug) {
        const response = await axios.post(
          `https://v1.vlv.am/api/category/${categorySlug}`,
          formData
        );
        dispatch(slice.actions.getFiltersSuccess(response.data));

        if (brandId && search) {
          navigate(`${currentLanguage !== "hy" ? "/" + currentLanguage : ""
            }/category/filter/${categorySlug}?b=${brandId}&fs=${search}`);
        } else if (brandId) {
          navigate(`${currentLanguage !== "hy" ? "/" + currentLanguage : ""
            }/category/filter/${categorySlug}?b=${brandId}`);
        } else if (search) {
          navigate(`${currentLanguage !== "hy" ? "/" + currentLanguage : ""
            }/category/filter/${categorySlug}?fs=${search}`);
        } else {
          navigate(`${currentLanguage !== "hy" ? "/" + currentLanguage : ""
            }/category/filter/${categorySlug}`);
        }
      } else if (brandId && brandSlug && !search) { 
        navigate(`${currentLanguage !== "hy" ? "/" + currentLanguage : ""
          }/brand/${brandSlug}/category`);
      } else {
        const response = await axios.post(
          `https://v1.vlv.am/api/category/${search}`,
          formData
        );
        const res = response?.data?.products.length;

        dispatch(slice.actions.getFiltersSuccess(response.data));

        if (!res) {
          navigate(`${currentLanguage !== "hy" ? "/" + currentLanguage : ""
            }/search-null`);
        } else {
          if(brandId){
            navigate(`${currentLanguage !== "hy" ? "/" + currentLanguage : ""
            }/category/filter/${WSsearchInfo?.search}?b=${brandId}&sh=1`);
          }else{
            navigate(`${currentLanguage !== "hy" ? "/" + currentLanguage : ""
            }/category/filter/${WSsearchInfo?.search}?sh=1`);
          } 
        }
      }
    } catch (error) {
      console.error("Error fetching filter:", error);
      navigate(`${currentLanguage !== "hy" ? "/" + currentLanguage : ""
        }/search-null`);

      dispatch(
        slice.actions.hasError(error.response?.data || "Error fetching filter")
      );
    }
  };
}


export function searchWsPageMan(slug, info, navigate, page, type, anyPath, isSalesDiscountPage = false, currentLanguage) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const formData = new FormData();
      let categorySlug = slug ?? 0;
      let responseSeo;
      if (anyPath) {
        responseSeo = await axiosInstance.post(
          `catalogs/${anyPath}`
        );

        if (responseSeo?.data?.category && responseSeo?.data?.brand) {
          categorySlug = responseSeo?.data?.category?.slug ?? 0;
          formData.append('b', responseSeo?.data?.brand?.id);
        } else if (responseSeo?.data?.category) {
          categorySlug = responseSeo?.data?.category?.slug ?? 0;
        } else if (responseSeo?.data?.brand) {
          dispatch(slice.actions.getFiltersSuccess(responseSeo?.data?.brand?.slug));
          return;
        }
      }




      const url = page ? 'https://v1.vlv.am/api/page/search/' : 'https://v1.vlv.am/api/category/'

      for (const [key, value] of Object.entries(info)) {
        if (value !== null && value !== "") {
          formData.append(key, value);
        }
      }
      if (type === 'sale') {
        formData.append('type', type);
        formData.append('id', page);
      }

      if (isSalesDiscountPage) {
        formData.append('d', 1);
        formData.append('discountSales', 1);
        categorySlug = 1;
        formData.append("slug", categorySlug);
      } else {
        if (categorySlug && !info?.sh) {
          formData.append("slug", categorySlug);
        } else {
          formData.append("search", categorySlug)
          formData.append("searchInfo", 1);
        }
      }
      if (categorySlug) {
        const response = await axios.post(
          `${url}${categorySlug}`,
          formData
        );
        if (!response?.data?.products?.length && !Object.keys(response?.data?.category)?.length) {
          navigate(`${currentLanguage}${currentLanguage !== "hy" ? "/" + currentLanguage : ""
            }/search-null`)
        }

        if (responseSeo) {
          if (response?.data) {
            response.data['brand_seo'] = responseSeo?.data?.brand?.id ? true : false;
          }
        }
        dispatch(slice.actions.getFiltersSuccess(response.data));
      }
    } catch (error) {
      console.error("Error fetching filter:", error);
      navigate(`${currentLanguage !== "hy" ? "/" + currentLanguage : ""
        }/search-null`)

      dispatch(
        slice.actions.hasError(error.response?.data || "Error fetching filter")
      );
    }
  };
}



